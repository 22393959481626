import ConfirmationModal from "../utils/ConfirmationModal";

export default class StudiesIndex {

    constructor(container = document, scroll_container = window) {

        this.container = container;
        this.window = scroll_container;

        this.page = 1;
        this.is_reset = true;
        this.timeout = null;
        this.orderFilter = null;

        this.confirmationModal = new ConfirmationModal();

        this.bindEvents();
        this.resetGrid();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        // Search events
        $("#search_text").keyup(this.onSearchTextKeyup);
        $("#search_order").change(this.onSearchOrderChange);
        $("input[name=study_status]").change(this.onStatusTypeChange);

        this.orderFilter = $('#search_order').selectpicker();
    }

    onDataChanged(data) {
    }

    onDestroy() {
        // Search events
        $("#search_text").off("keyup", this.onSearchTextKeyup);
        $("#search_order").off("change", this.onSearchOrderChange);
        $("input[name=study_status]").off("change", this.onStatusTypeChange);

        this.orderFilter.selectpicker('destroy');
        $(this.container).unbind('scroll', this.onScroll);
    }

    addGridEvents(){

        // Destroy Study
        $(".js-destroy-study").click(this.onDestroyStudy);
    }

    removeGridEvents(){

        // Destroy Study
        $(".js-destroy-study").off("click", this.onDestroyStudy);
    }

    resetEvents(){
        this.removeGridEvents();
        this.addGridEvents();
    }


    /** ----------- START Ajax Search -----------  **/
    onSearchTextKeyup(e){
        let self = application.current_handler;
        clearTimeout(self.timeout);
        self.timeout = setTimeout(function (){
            self.resetGrid();
        }, 500)
    }

    onSearchOrderChange(e){
        let self = application.current_handler;
        self.resetGrid();
    }

    onStatusTypeChange(e){
        let self = application.current_handler;
        self.resetGrid();
    }
    /** ----------- START Ajax Search -----------  **/


    /** ----------- START Ajax Index Grid -----------  **/
    resetGrid(){
        this.is_reset = true
        this.page = 1;
        this.ajax_render_grid();
    }

    ajax_render_grid(){
        $(this.container).unbind('scroll', this.onScroll);

        $.ajax({
            url: $("#studies_container").data("url"),
            data: this.ajaxIndexData(),
            success: this.ajaxIndexSuccess,
            error: this.ajaxIndexError,
        });
    }

    ajaxIndexData(){
        return {
            search: $("#search_text").val(),
            page: this.page,
            order_field: $("#search_order option:selected").data("field"),
            order_dir: $("#search_order option:selected").data("order"),
            study_status: $("input[name=study_status]:checked").val(),
        }
    }

    ajaxIndexSuccess(html){
        let self = application.current_handler;
        let $container = $("#studies_container");
        if(self.is_reset){
            self.is_reset = false
            $container.html("");
        }

        let before = $("[id^=study_]").length;
        $container.append(html);
        let after = $("[id^=study_]").length;

        if(before !== after){ $(self.container).on('scroll', self.onScroll); } // Scroll event

        self.page++;
        self.resetEvents();
    }

    ajaxIndexError(data){
        console.log(data);
    }
    /** ----------- END Ajax Index Grid -----------  **/


    /** ----------- START Scroll -----------  **/
    onScroll(e){
        application.current_handler.load_page_if_visible()
    }

    load_page_if_visible(){
        let $el = $(".js-reload");
        let $container = $(this.window);

        let top_of_element = $el.offset().top;
        let bottom_of_element = $el.offset().top + $el.outerHeight();
        let bottom_of_screen = $container.scrollTop() + $container.innerHeight();
        let top_of_screen = $container.scrollTop();

        if ( (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element) ) {
            application.current_handler.ajax_render_grid();
        }
    }
    /** ----------- END Scroll -----------  **/


    /** ----------- START Destroy -----------  **/
    onDestroyStudy(e){
        e.preventDefault();
        let self = application.current_handler;
        let $el = $(this);
        self.confirmationModal.displayConfirm(
            I18n.t("common.title_delete"),
            I18n.t("backoffice.studies.destroy_confirmation_description", {title: $(this).data("title")}),
            {
                positive_btn: {
                    type: "danger",
                    label: I18n.t("common.destroy"),
                    callback: function(){
                        self.ajaxDestroy($el.attr("href"))
                    }
                },
                negative_btn: {
                    label: I18n.t("common.cancel")
                }
            });
    }

    ajaxDestroy(url){
        $.ajax({
            url: url,
            method: "delete",
            success: function (data){
                application.successNotification(I18n.t("backoffice.studies.deleted"))
                $("#study_"+data.id).remove();
            },
            error: function(){
                console.log("error");
            }
        })
    }
    /** ----------- END Destroy -----------  **/
}