import ConfirmationModal from "../utils/ConfirmationModal";

export default class StagesShow {

    constructor() {
        this.questionsDatatable = null;

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.setQuestionsDatatable();
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.questionsDatatable.destroy();
    }


    setQuestionsDatatable(){
        let self = this;
        this.questionsDatatable = $('#question-datatable').DataTable({
            dom: 'rtp',
            language: require('../utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            columnDefs: [
                { orderable: false, searchable: false, targets: -1 }
            ],
            "drawCallback": function( settings ) {
                application.reloadDeleteConfirm();
            }
        })

        let $search = $('#questionSearch')

        if($search.length !== 0){
            $search.keyup(function(){
                self.questionsDatatable.search($(this).val()).draw();
            })
        }
    }
}