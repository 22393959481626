import Sortable from "sortablejs";
import ConfirmationModal from "../../utils/ConfirmationModal";

export default class QuestionOptions {

    constructor() {
        this.questionTypeSelect = null;
        this.questionType = null;
        this.sortable = null;
        this.optionCardUrl = "";
        this.extraCardUrl = "";
        this.optionUrl = "";
        this.question_id = null;
        this.timeout = null;
        this.confirmationModal = new ConfirmationModal();
        this.questionTypeSelect = $('#question_question_type').selectpicker();
    }


    bindEvents() {
        $("#question_question_type").change(this.onTypeChange);
        this.bindOptionsEvents();
        this.disableDeleteOption();
    }

    onDataChanged(data) {
        if(data.optionCardUrl !== null && data.optionCardUrl !== undefined){
            this.optionCardUrl = data.optionCardUrl;
        }
        if(data.extraCardUrl !== null && data.extraCardUrl !== undefined){
            this.extraCardUrl = data.extraCardUrl;
        }
        if(data.optionUrl !== null && data.optionUrl !== undefined){
            this.optionUrl = data.optionUrl;
        }
        if(data.questionType !== null && data.questionType !== undefined){
            this.questionType = data.questionType;
        }
        if(data.question_id !== null && data.question_id !== undefined){
            this.question_id = data.question_id;
        }
    }

    onDestroy() {
        this.questionTypeSelect.selectpicker('destroy');
        $("#question_question_type").off("change", this.onTypeChange);
        this.bindOptionsEvents();
    }

    resetOptionsEvents(){
        this.removeOptionsEvents();
        this.bindOptionsEvents();
    }

    bindOptionsEvents(){
        $("#new_option").click(this.addOption);
        $(".js-delete-option").click(this.removeOption);

        let $optionContainer = $("#option_container")
        this.sortable = Sortable.create($optionContainer.get(0), {
            animation: 300,
            ghostClass: "sortable_position",
            dragClass: "sortable_dragging",
            handle: '#option_container .handle',
            forceFallback: true
        });

        $optionContainer.on( "dragend", function(){ $(document).trigger("options:updated") })
        $optionContainer.keyup(this.onTyping)
    }

    removeOptionsEvents(){
        $("#new_option").off("click", this.addOption);
        $(".js-delete-option").off("click", this.removeOption);
        this.sortable.destroy();
    }

    onTypeChange(e){
        let self = application.current_handler.questionOptions;
        let method = $("[name='_method']");
        console.log(method.length);
        console.log(method.val());
        if(method.length === 0){
            self.updateQuestionType()
        }else{
            self.confirmationModal.displayConfirm(
                I18n.t("common.title_update"),
                I18n.t("backoffice.questions.options.update_confirmation_html",
                    {type: I18n.t("activerecord.attributes.question.question_types."+$("#question_question_type").val())}),
                {
                    positive_btn: {
                        type: "danger",
                        label: I18n.t("common.update_and_destroy"),
                        callback: function(){
                            self.updateQuestionType()
                        }
                    },
                    negative_btn: {
                        label: I18n.t("common.cancel"),
                        callback: function (){
                            $("#question_question_type option[value="+self.questionType+"]").prop("selected", true);
                            self.questionTypeSelect.selectpicker('refresh');
                        }
                    }
                }
            );
        }
    }

    updateQuestionType(){
        this.questionType = $("#question_question_type").val()
        this.updateOptionCard();
        this.updateExtraCard();
    }

    updateOptionCard(){
        let self = application.current_handler.questionOptions;
        $.ajax({
            url: self.optionCardUrl,
            method: "POST",
            data: {question_type: $("#question_question_type").val()},
            success: self.appendQuestionType
        })
    }

    updateExtraCard(){
        let self = application.current_handler.questionOptions;
        $.ajax({
            url: self.extraCardUrl,
            method: "POST",
            data: {
                question_type: $("#question_question_type").val(),
                id: self.question_id
            },
            success: self.replaceExtras
        })
    }

    appendQuestionType(html){
        let self = application.current_handler.questionOptions;
        $("#option_card").empty().append(html)
        self.resetOptionsEvents();
        $(document).trigger("options:updated");
    }

    replaceExtras(html){
        $("#form_extras").empty().append(html)
    }

    addOption(e){
        let self = application.current_handler.questionOptions;
        $.ajax({
            url: self.optionUrl,
            method: "POST",
            data: {
                question_type: $("#question_question_type").val(),
                code: self.getNextCode()
            },
            success: self.appendOption
        })
    }

    appendOption(html){
        let self = application.current_handler.questionOptions;
        $("#option_container").append(html);
        self.resetOptionsEvents();
        self.disableDeleteOption();
        $(document).trigger("options:updated");
    }

    getNextCode(){
        let codes = $("#option_card input[name*=code]").map(function(){
            return $(this).val();
        }).get();
        let code = 1;
        while(codes.indexOf(code.toString()) !== -1){
            code++;
        }
        console.log(code);
        return code;
    }

    removeOption(e){
        let $el = $(this)
        let self = application.current_handler.questionOptions;
        self.confirmationModal.displayConfirm(
            I18n.t("common.title_delete"),
            I18n.t("backoffice.questions.options.destroy_confirmation_description"),
            {
                positive_btn: {
                    type: "danger",
                    label: I18n.t("common.destroy"),
                    callback: function(){
                        $("#option_"+$el.data("id")).remove();
                        self.disableDeleteOption();
                        $(document).trigger("options:updated");
                    }
                },
                negative_btn: {
                    label: I18n.t("common.cancel")
                }
            }
        );
    }

    disableDeleteOption(){
        if($(".card[id^=option_]").length > 1){
            $(".js-delete-option").prop("disabled", false);
        }else{
            $(".js-delete-option").prop("disabled", true);
        }
    }

    onTyping(e){
        let self = application.current_handler.questionOptions;
        clearTimeout(self.timeout);
        self.timeout = window.setTimeout(function() {
            $(document).trigger("options:updated")
        },500);
    }

    reloadOptions(canUpdate){
        if(canUpdate){
            this.bindEvents();
        }else{
            $("#option_container .handle").css("opacity", "0.7").css("cursor", "auto")
            $("#new_option").addClass("d-none")
            $("input[name^='question[extra]']").attr("readonly", true);
        }
    }

}