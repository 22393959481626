export default class QuestionPreview {

    constructor() {
        this.$previewCard = $('#preview_card');

        this.bindEvents();
        this.updatePreview();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.setStickyCard();
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    setStickyCard(){
        let self = this;

        // sticky cant work if parents have overflows other than visible
        // Remove overflow from parents
        let parent = document.querySelector('#preview_card').parentElement;
        while (parent) {
            const hasOverflow = getComputedStyle(parent).overflow;
            if (hasOverflow !== 'visible') {
                $(parent).css("overflow", "visible")
            }
            parent = parent.parentElement;
        }
    }

    updatePreview(){
        let arrContent = this.getContentData();
        let arrOptions = this.getOptionsData();
        let $iframe = $("#question_preview")

        $.ajax({
            url: $iframe.data("url"),
            data: {
                questionType: $("#question_question_type").val(),
                contents: arrContent,
                options: arrOptions,
            },
            success: function (html){

                let previewIframe = $iframe[0];
                let content = previewIframe.contentWindow || ( previewIframe.contentDocument.document || previewIframe.contentDocument);

                content.document.open();
                content.document.write(html);
                content.document.close();
            }
        })
    }

    getContentData(){
        return $.map($("#content_container").children(), function(item){
            let contentType = $(item).find("input[name*='[content_type]']").val()
            let content = $(item).find("[name*='[content]']").val()
            let extra = $(item).find("[name*='[extra]']").val()
            let medium_url = $(item).find("input[name*='[medium_id]']").next("img").attr("src")
            return { contentType: contentType, content: content, extra: extra, medium_url: medium_url }
        })
    }

    getOptionsData(){
        return $.map($("#option_container").children(), function(item){
            let code = $(item).find("input[name*='[code]']").val()
            let title = $(item).find("input[name*='[title]']").val()
            let extra = $(item).find("input[name*='[extra]']").val()
            return { code: code, title: title, extra: extra }
        })
    }
}