import ConfirmationModal from "../utils/ConfirmationModal";

export default class StudiesShow {

    constructor() {
        this.studyStatus = null;
        this.participantStatus = null;
        this.currentType = null;
        this.ajaxUpdateUrl = "";
        this.confirmationModal = new ConfirmationModal();

        this.participantsDatatable = null;
        this.stagesDatatable = null;

        this.bindEvents();
        application.setTemplateValidation(StudiesShow.validationRules(), "#participant_form");
        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;

        this.studyStatus = $("#study_status").selectpicker();
        this.currentType = $("#participant_participant_type").selectpicker();
        $("#study_status").change(this.statusChange)

        $("#confirm_status_change").click(this.onConfirmChange)
        $("#cancel_status_change").click(this.onCancelChange)

        $("[name=study_show]").change(this.onTabChange)

        $("#export_participants").on("click", function() {
            self.participantsDatatable.button( '.buttons-csv' ).trigger();
        });

        $("#check_all_participants").change(this.onSelectAll)
        $("#destroy_participants").click(this.onDestroyAll);
        $("#study_data_export").click(this.exportData);
    }

    onDataChanged(data) {
        this.currentStatus = data.current_status;
        this.ajaxUpdateUrl = data.ajax_update_url;
        this.canUpdate = data.canUpdate;

        this.setDatatables();
    }

    onDestroy() {
        this.studyStatus.selectpicker('destroy');
        this.currentType.selectpicker('destroy');
        $("#study_status").off("change", this.statusChange)

        $("#confirm_status_change").off("click", this.onConfirmChange)
        $("#cancel_status_change").off("click", this.onCancelChange)

        $("[name=study_show]").off("change", this.onTabChange)
        $("#destroy_participants").off("click", this.onDestroyAll);
        $("#study_data_export").off("click", this.exportData);

        this.destroyDatatables();
    }

    reloadDataTables(){
        this.destroyDatatables()
        this.setDatatables()
    }

    setDatatables(){
        this.setParticipantsDatatable();
        this.setStagesDatatable();
    }

    destroyDatatables(){
        if(this.participantsDatatable !== null){
            this.participantsDatatable.destroy();
        }

        if(this.stagesDatatable !== null){
            this.stagesDatatable.destroy();
        }
    }

    statusChange(e){
        let self = application.current_handler;
        self.showConfirmationModal($(this).val());
    }

    showConfirmationModal(status){
        $("#new_status").text(I18n.t("activerecord.attributes.study.study_status."+status));
        $("#confirm_status_change").data("status", status);
        $("#status_confirmation_modal").modal("show");
    }

    onConfirmChange(e){
        let self = application.current_handler
        self.currentStatus = $(this).data("status");

        $.ajax({
            url: self.ajaxUpdateUrl,
            method: "PUT",
            data: { study: {study_status: self.currentStatus } },
            success: function(data){
                self.canUpdate = data.can_update;
                self.reloadDataTables();
                if(self.canUpdate){
                    $(".js-delete-study").removeClass("d-none")
                    $(".js-new-stage").removeClass("d-none")
                }else{
                    $(".js-delete-study").addClass("d-none")
                    $(".js-new-stage").addClass("d-none")
                }
                application.successNotification(I18n.t("backoffice.studies.show.status_updated"))
            },
            error: function(){},
        })
    }

    onCancelChange(e){
        let self = application.current_handler
        $("#study_status option[value="+self.currentStatus+"]").prop("selected", true);
        self.studyStatus.selectpicker('refresh');
    }

    onTabChange(e){
        if($("[name=study_show]:checked").val() === "stage" ){
            $("#stage").removeClass("d-none")
            $("#participants").addClass("d-none")
        }else{
            $("#stage").addClass("d-none")
            $("#participants").removeClass("d-none")
        }
    }

    static validationRules(){
        return {
            "participant_number": {
                required: true,
                min: 1,
                max: 10000
            }
        }
    }


    setParticipantsDatatable(){
        let self = this;
        this.participantsDatatable = $('#participants-datatable').DataTable({
            dom: 'rtp',
            language: require('../utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            columnDefs: [
                { orderable: false, searchable: false, targets: 0, className: 'select-checkbox' },
                { orderable: false, searchable: false, targets: -1 },
            ],
            order: [[3, 'desc']],
            select: {
                style: "multiple",
                selector: 'td:nth-child(-n+4)'
            },
            buttons: [
                {
                    extend: 'csv',
                    exportOptions: {
                        columns: [ 1,2,3 ],
                        selected: true
                    },
                    title: 'Participants'
                }
            ],
            "drawCallback": function( settings ) {
                application.reloadDeleteConfirm();
            }
        })

        let $search = $('#participantsSearch')

        if($search.length !== 0){
            $search.keyup(function(){
                self.participantsDatatable.search($(this).val()).draw();
            })
        }
    }


    setStagesDatatable(){
        let self = this;

        if(self.canUpdate){
            $("#stages-datatable tr td:first-child").addClass("handle-draggable")
            $(".js-actions").removeClass("d-none").addClass("d-inline-block")
        }else{
            $("#stages-datatable tr td:first-child").removeClass("handle-draggable")
            $(".js-actions").removeClass("d-inline-block").addClass("d-none")
        }

        this.stagesDatatable = $('#stages-datatable').DataTable({
            dom: 'rtp',
            rowReorder: self.canUpdate,
            paging: false,
            language: require('../utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            order: [[0, 'asc']],
            columnDefs: [
                { orderable: false, searchable: false, targets: -1 },
                { orderable: false, searchable: false, targets: 2 },
                { orderable: false, searchable: false, targets: 3 },
            ],
            "drawCallback": function( settings ) {
                application.reloadDeleteConfirm();
            }
        })

        let $search = $('#stageSearch')

        if($search.length !== 0){
            $search.keyup(function(){
                self.stagesDatatable.search($(this).val()).draw();
            })
        }

        this.stagesDatatable.on( 'row-reorder', function ( e, diff, edit ) {

            let successCount = 0;
            let errorCount = 0;

            for ( var i=0, ien=diff.length ; i<ien ; i++ ) {
                let stage_id = $(diff[i].node).data("id");
                let study_id = $(diff[i].node).data("study-id");
                let stage_order = diff[i].newPosition

                $.ajax({
                    url: `${study_id}/stages/${stage_id}/ajax_update_order`,
                    method: "PUT",
                    data: {
                        stage_order: stage_order
                    },
                    success: function (){
                        successCount++;
                        if(successCount === diff.length){
                            application.successNotification("success")
                        }
                    },
                    error: function (){
                        errorCount++;
                        if(errorCount > 0 && (errorCount+successCount) === diff.length){
                            application.errorNotification("error")
                        }
                    }
                })
            }
        } );
    }

    onSelectAll(e){
        let self = application.current_handler
        if($(this).prop("checked")){
            self.participantsDatatable.rows().select();
        }else{
            self.participantsDatatable.rows().deselect();
        }
    }

    onDestroyAll(e){
        let self = application.current_handler
        let selectedRows = self.participantsDatatable.rows({selected: true});
        let count = selectedRows.count();

        if(count > 0){
            self.confirmationModal.displayConfirm(
                I18n.t("common.title_delete"),
                I18n.t("backoffice.participants.destroy_confirmation_description", {count: count}),
                {
                    positive_btn: {
                        type: "danger",
                        label: I18n.t("common.destroy"),
                        callback: self.destroySelected
                    },
                    negative_btn: { label: I18n.t("common.cancel") }
                }
            );
        }
    }

    destroySelected(){
        application.openLoader();

        let self = application.current_handler
        let selectedRows = self.participantsDatatable.rows({selected: true});
        let destroyIds = []
        $.map( selectedRows.nodes().to$(), function( el, i ) {
            destroyIds.push($(el).data("id"))
        });

        $.ajax({
            url: $("#destroy_participants").data("url"),
            method: "DELETE",
            data: { participant_ids: destroyIds }
        })
    }

    exportData(){
        application.openLoader();

        $.ajax({
            url: $(this).data("url"),
            method: "POST",
            data: {},
            success: function (data){
                application.closeLoader();
                window.open(data.url, '_blank')
            }
        })
    }
}