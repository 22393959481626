import ConfirmationModal from "../../utils/ConfirmationModal";

export default class QuestionRelated {

    constructor() {
        this.question_id = null;
        this.canUpdate = false
        this.confirmationModal = new ConfirmationModal();
        this.bindEvents();
    }


    bindEvents() {
        $("#add_relation").click(this.createRelation);
        this.bindRelationsEvents();
        this.setRelationOptionsVisibility();
    }

    onDataChanged(data) {
        if(data.question_id !== null && data.question_id !== undefined){
            this.question_id = data.question_id;
        }

        if(data.canUpdate !== undefined && data.canUpdate !== null){
            this.canUpdate = data.canUpdate;
        }
    }

    onDestroy() {
        this.removeRelationsEvents()
    }

    resetRelationsEvents(){
        this.removeRelationsEvents()
        this.bindRelationsEvents()
    }

    bindRelationsEvents(){
        $(".js-delete-relation").click(this.removeRelation);
        $("select[name*=related_question_id]").change(this.onQuestionChange).selectpicker();
        $("select[name*=relation_type]").change(this.onRelationChange).selectpicker();
        $("select[name*=option_ids]").select2({
            multiple: true,
            closeOnSelect: false
        });
    }

    removeRelationsEvents(){
        $(".js-delete-relation").off("click", this.removeRelation)
        $("select[name*=related_question_id]").off("change", this.onQuestionChange).selectpicker('destroy');
        $("select[name*=relation_type]").off("change", this.onRelationChange).selectpicker('destroy');
        $("select[name*=option_ids][data-select2-id*='select2']").select2('destroy');
    }

    createRelation(e){
        let $el = $(this);
        let self = application.current_handler.questionRelated;
        e.preventDefault();

        $.ajax({
            url: $el.data("url"),
            method: "POST",
            data: {question_id: self.question_id},
            success: self.addRelation
        })
    }

    addRelation(html){
        let self = application.current_handler.questionRelated;

        $("#relations_container").append(html);
        self.resetRelationsEvents();
    }

    removeRelation(e){
        e.preventDefault();
        let $el = $(this);
        let self = application.current_handler.questionRelated;

        self.confirmationModal.displayConfirm(
            I18n.t("common.title_delete"),
            I18n.t("backoffice.questions.related_questions.destroy_confirmation_description", {title: $(this).data("title")}),
            {
                positive_btn: {
                    type: "danger",
                    label: I18n.t("common.destroy"),
                    callback: function(){
                        $("#related_question_"+$el.data("id")).remove();
                    }
                },
                negative_btn: { label: I18n.t("common.cancel") }
            }
        );
    }

    onQuestionChange(e){
        let self = application.current_handler.questionRelated;
        let $el = $(this);
        let $options = $(`#related_question_${$el.data("id")}_option_ids`)
        let $types = $(`#related_question_${$el.data("id")}_relation_type`)
        let $types_block = $("#relation_type_"+$el.data("id"))

        if($el.val() !== ""){
            $.ajax({
                url: $el.data("url"),
                data: {
                    question_id: $el.val(),
                    relation_id: $el.data("id"),
                },
                success: function (html){
                    $types_block.html(html);
                    self.resetRelationsEvents();
                    self.setRelationOptionsVisibility()
                }
            });
        }else{
            $options.prop("disabled", true);
            $options.find("option:selected").prop("selected", false);
            $types.prop("disabled", true);
            $types.find("option:selected").prop("selected", false);
            self.resetRelationsEvents();
        }
    }

    onRelationChange(e){
        let self = application.current_handler.questionRelated;
        let $el = $(this);
        let $options = $(`#related_question_${$el.data("id")}_option_ids`)
        self.setRelationOptionsVisibility();
        $options.find("option:selected").prop("selected", false);
        self.resetRelationsEvents();
    }

    setRelationOptionsVisibility(){
        let self = this;
        $("[name*=relation_type]").each(function (){
            let $el = $(this);
            let $options = $(`#related_question_${$el.data("id")}_option_ids`)

            if(["at_least_not_selected", "at_least_selected"].includes($el.val())){
                if(self.canUpdate){
                    $options.prop("disabled", false);
                }
                $options.prop("required", true);
            }else{
                $options.prop("disabled", true);
                $options.prop("required", false);
            }

            $(`#related_question_${$el.data("id")}_option_ids-error`).remove()
            $(`label[for=related_question_${$el.data("id")}_option_ids]`).removeClass("text-danger")

            if(["at_least_not_selected", "has_answer"].includes($el.val())){
                $(`[name='related_question[${$el.data("id")}][not_selected]']`).val("1")
            }else{
                $(`[name='related_question[${$el.data("id")}][not_selected]']`).val("0")
            }
        })
    }
}