export default class ServiceNotificationIndex {

    constructor() {
        this.datatable = null;
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);

        this.initDatatable();
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    initDatatable(){
        let self = this;

        let $el = $('#notification_datatable')
        if($el.length <= 0){ return false }

        this.fttoDataTable = $el.DataTable({
            dom: 'rtp',
            responsive: false,
            serverSide: true,
            "pageLength": 20,
            order: [[0, 'desc']],
            language: require('../../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            columnDefs: [
            ],
            "ajax": {
                'url': $el.data("url"),
                'data': function(data){
                    data.search["value"] = $("#customSearch").val();
                }
            },
            "columns": [
                { "data": "created_at" },
                { "data": "error" },
                { "data": "token" },
                { "data": "body" },
            ],
            /*drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }*/
        })


        let $search = $('#customSearch')

        if($search.length !== 0){
            $search.keyup(function(){
                self.fttoDataTable.search($(this).val()).draw();
            })
        }
    }
}