export default class DepartmentsIndex {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        $("#department_new").click(this.clickNew)
        $(".department_edit").click(this.clickEdit)
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    clickNew(e){
        e.preventDefault();
        let ajax_data = {
            url: "/departments/ajax_new",
            data: {},
            method: "get",
            success: function (html){
                $("#department_modal_content").empty().append(html);
                $("#department_modal").modal("show");
                application.setTemplateValidation(DepartmentsIndex.validationRules());
            }
        }

        $.ajax(ajax_data)
    }

    clickEdit(e){
        e.preventDefault();
        let ajax_data = {
            url: "/departments/"+$(this).data("id")+"/ajax_edit",
            data: {},
            method: "get",
            success: function (html){
                $("#department_modal_content").empty().append(html);
                $("#department_modal").modal("show");
                application.setTemplateValidation(DepartmentsIndex.validationRules());
            }
        }

        $.ajax(ajax_data)
    }

    static validationRules(){
        return {
            "department[name]": {
                required: true
            }
        }
    }
}