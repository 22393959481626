import MediaManager from "../utils/MediaManager";

export default class StudiesForm {

    constructor() {
        this.timeZoneSelect = null;
        this.mediaManager = new MediaManager($("#media-body").get(0), $("#media-body").get(0), "radio_medium");

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        $("#study_starts_at").change(this.updateEndMinDate);

        this.timeZoneSelect = $('#study_time_zone').selectpicker();
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.timeZoneSelect.selectpicker('destroy');
        this.mediaManager.onDestroy();
    }

    updateEndMinDate(){
        let $inputStarts = $("#study_starts_at");
        let $inputEnds = $("#study_ends_at");

        const startsAt = Date.parse($inputStarts.val());
        const endsAt = Date.parse($inputEnds.val());

        $inputEnds.attr("min", $inputStarts.val())
        if(startsAt > endsAt){
            $inputEnds.val($inputStarts.val())
        }
    }
}