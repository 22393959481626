

export default class AdminMenuForm {

    constructor() {
        $('.dd').nestable({ group: 1, maxDepth: 2 });
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        $('#create_element').click(this.createElement);
        $('.js-name-update').keyup(this.updateName);
        $('#admin_menu_form').submit(this.beforeSend);
        $('.js-delete-item').click(this.deleteElement);
    }

    onDataChanged(data) {
    }

    onDestroy() {

    }

    createElement(e) {
        e.preventDefault();
        let self = application.current_handler

        let index = 0;

        $('.dd-item').each(function (){
            if(parseInt($(this).data("id")) > index){
                index = parseInt($(this).data("id"));
            }
        })

        index++;

        let ajax_data = {
            url: "/admin_menus/ajax_create_item",
            data: {
                menu_item: {
                    name: $('#menu_item_name').val(),
                    controller: $('#menu_item_controller').val(),
                    action: $('#menu_item_action').val(),
                    url: $('#menu_item_url').val(),
                    css: $('#menu_item_css').val(),
                    icon: $('#menu_item_icon').val(),
                    index: index
                }
            },
            method: "get",
            success: function (data){
                $('#menu_list').append(data);
                $('.dd').nestable('destroy');
                $('.dd').nestable({ group: 1, maxDepth: 2 });
                $('.dd-empty').remove();
                $('.js-name-update').unbind('keyup', self.updateName).keyup(self.updateName);
                $('.js-delete-item').unbind('click', self.deleteElement).click(self.deleteElement);

            }
        }

        $.ajax(ajax_data)
    }

    updateName(e) {
        if($(this).val() !== ''){
            $("#menu_item_title_"+$(this).data('id')).text(I18n.t($(this).val()));
        }else{
            $("#menu_item_title_"+$(this).data('id')).text('blanc');
        }
    }

    beforeSend(e) {
        if($(this).data("can-send") === undefined){
            $(this).data("can-send", true)
            e.preventDefault();
            let self = application.current_handler
            self.serializeMenu();
            $(this).submit();
        }
    }

    serializeMenu() {
        let string = JSON.stringify($('.dd').nestable('toArray'));
        let string2 = JSON.stringify($('.dd').nestable('serialize'));
        $('#menu_order').val(string2);
    }

    deleteElement(e) {
        e.preventDefault();
        $('#element_'+$(this).data('id')).remove();
    }
}