import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/DashboardIndex";
import PermissionsIndex from "../controllers/PermissionsIndex";
import AdminsIndex from "../controllers/AdminsIndex";
import AdminsEdit from "../controllers/AdminsEdit";
import AdminsNew from "../controllers/AdminsNew";
import RoleIndex from "../controllers/RoleIndex";
import RoleEdit from "../controllers/RoleEdit";
import ProfileIndex from "../controllers/ProfileIndex";
import AdminMenuIndex from "../controllers/AdminMenuIndex";
import AdminMenuForm from "../controllers/AdminMenuForm";
import DepartmentsIndex from "../controllers/DepartmentsIndex";
import MediaIndex from "../controllers/MediaIndex";
import StudiesIndex from "../controllers/StudiesIndex";
import StudiesForm from "../controllers/StudiesForm";
import StudiesShow from "../controllers/StudiesShow";
import StagesForm from "../controllers/StagesForm";
import StagesShow from "../controllers/StagesShow";
import QuestionsForm from "../controllers/question_form/QuestionsForm";
import ServiceNotificationIndex from "../controllers/ServiceNotificationIndex";

export default class Routes extends ConfigRoutes{

    static routes = {
        "DashboardIndex": DashboardIndex,
        "PermissionsIndex": PermissionsIndex,
        "AdminsIndex": AdminsIndex,
        "AdminsEdit": AdminsEdit,
        "AdminsUpdate": AdminsEdit,
        "AdminsNew": AdminsNew,
        "AdminsCreate": AdminsNew,
        "RolesIndex": RoleIndex,
        "RolesEdit": RoleEdit,
        "RolesNew": RoleEdit,
        "RolesUpdate": RoleEdit,
        "RolesCreate": RoleEdit,
        "ProfileIndex": ProfileIndex,
        "AdminMenusIndex": AdminMenuIndex,
        "AdminMenusNew": AdminMenuForm,
        "AdminMenusCreate": AdminMenuForm,
        "AdminMenusEdit": AdminMenuForm,
        "AdminMenusUpdate": AdminMenuForm,
        "DepartmentsIndex": DepartmentsIndex,
        "MediaIndex": MediaIndex,
        "StudiesIndex": StudiesIndex,
        "StudiesNew": StudiesForm,
        "StudiesCreate": StudiesForm,
        "StudiesEdit": StudiesForm,
        "StudiesUpdate": StudiesForm,
        "StudiesShow": StudiesShow,
        "StagesNew": StagesForm,
        "StagesCreate": StagesForm,
        "StagesEdit": StagesForm,
        "StagesUpdate": StagesForm,
        "StagesShow": StagesShow,
        "QuestionsNew": QuestionsForm,
        "QuestionsCreate": QuestionsForm,
        "QuestionsEdit": QuestionsForm,
        "QuestionsUpdate": QuestionsForm,
        "ServiceNotificationsApnsp8Index": ServiceNotificationIndex,
        "ServiceNotificationsFcmIndex": ServiceNotificationIndex,
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}