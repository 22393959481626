import QuestionRelated from "./QuestionRelated";
import QuestionContent from "./QuestionContent";
import MediaManager from "../../utils/MediaManager";
import QuestionOptions from "./QuestionOptions";
import QuestionPreview from "./QuestionPreview";
import ConfirmationModal from "../../utils/ConfirmationModal";

export default class QuestionsForm {

    constructor() {
        this.contentType = null;
        this.initialState = $('#crud_form').serialize()
        this.isSubmiting = false;
        this.canUpdate = true;
        this.validateRelatedUrl = "";

        this.mediaContainer = $("#media-body").get(0)
        this.mediaManager = new MediaManager(this.mediaContainer, this.mediaContainer, "radio_medium");

        this.questionRelated = new QuestionRelated();
        this.questionContent = new QuestionContent();
        this.questionOptions = new QuestionOptions();
        this.questionPreview = new QuestionPreview();
        this.confirmationModal = new ConfirmationModal();


        this.bindEvents();
        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.contentType = $('#select_content_type').selectpicker();

        $(document).on("question_content_updated", this.reloadEvents)
        $(document).on("options:updated", this.updatePreview);
        $(document).on("contents:updated", this.updatePreview);
        $(document).on("turbolinks:before-visit", this.preventLeaveTurbolinks);
        $(window).on("beforeunload", this.preventLeave);
        $("#crud_form").submit(this.onSubmit);
        $("#crud_form").one("submit", this.onSubmitOne);
    }

    onDataChanged(data) {
        this.questionRelated.onDataChanged(data);
        this.questionOptions.onDataChanged(data);

        if(data.canUpdate !== undefined && data.canUpdate !== null){
            this.canUpdate = data.canUpdate;
        }
        if(data.validateRelatedUrl !== undefined && data.validateRelatedUrl !== null){
            this.validateRelatedUrl = data.validateRelatedUrl;
        }
        this.updateFieldsReadOnly()
    }

    onDestroy() {
        this.contentType.selectpicker('destroy');

        $(document).off("question_content_updated", this.reloadEvents)
        $(document).off("options:updated", this.updatePreview);
        $(document).off("contents:updated", this.updatePreview);
        $(document).off("turbolinks:before-visit", this.preventLeaveTurbolinks);
        $(window).off("beforeunload", this.preventLeave);
        $("#crud_form").off("submit", this.onSubmit);

        this.questionRelated.onDestroy();
        this.questionContent.onDestroy();
        this.questionOptions.onDestroy();
        this.questionPreview.onDestroy();
    }

    reloadEvents(e){
        let self = application.current_handler
        application.resetWysiwyg();
        self.mediaManager.resetSelectEvents();
    }

    updatePreview(e){
        application.current_handler.questionPreview.updatePreview()
    }

    onSubmit(e){
        let self = application.current_handler;
        self.isSubmiting = true;
    }

    onSubmitOne(e){
        e.preventDefault();
        let $el = $(this);
        let self = application.current_handler;

        $.ajax({
            url: self.validateRelatedUrl,
            method: "get",
            data: $('#crud_form [name^=related_question]').serialize(),
            success: function (data){
                $el.submit();
            },
            error: function(data){
                $("#error_related_questions_content_modal").empty().append(data.responseText);
                $("#error_related_questions_modal").modal("show");
                $("#crud_form").one("submit", self.onSubmitOne);
                $("#go_next").remove()
            }
        })

    }

    preventLeave(e){
        let self = application.current_handler;
        if(self.initialState === $('#crud_form').serialize() || self.isSubmiting){
            self.isSubmiting = false;
            return undefined
        }
        return '';
    }

    preventLeaveTurbolinks(e){
        let self = application.current_handler;
        if (self.initialState !== $('#crud_form').serialize() && !self.isSubmiting) {
            self.isSubmiting = false;
            e.preventDefault();

            self.confirmationModal.displayConfirm(
                I18n.t("common.warning"),
                I18n.t("common.confirm_leave"),
                {
                    positive_btn: {
                        type: "warning",
                        label: I18n.t("common.leave"),
                        callback: function(){
                            self.isSubmiting = true;
                            Turbolinks.visit(e.originalEvent.data.url, { action: "replace" })
                        }
                    },
                    negative_btn: { label: I18n.t("common.cancel") }
                }
            );
        }
    }

    updateFieldsReadOnly(){
        $("input[name^=option]").attr("readonly", !this.canUpdate);
        this.questionOptions.reloadOptions(this.canUpdate);
    }

    setNext(){
        $("#crud_form").append('<input type="hidden" name="next" value="next" id="go_next">')
    }
}