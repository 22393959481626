import MediaManager from "../utils/MediaManager";
import ConfirmationModal from "../utils/ConfirmationModal";

export default class StagesForm {

    constructor() {
        this.resumeStage = null;
        this.confirmationModal = new ConfirmationModal();
        this.mediaManager = new MediaManager($("#media-body").get(0), $("#media-body").get(0), "radio_medium");

        this.bindEvents();
        this.updateDateInterval();
        this.updateEndingText();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.resumeStage = $('#stage_stage_recovery').selectpicker();

        $("#add_notification").click(this.createNotification);
        $(".js-delete-notification").click(this.removeNotification);
        $(".js-update-text-count").keyup(this.updateTextCount);
        $("#stage_starts_at").change(this.updateEndMinDate);
        $("#stage_is_interval").change(this.updateDateInterval);
        $("#stage_has_ending_text").change(this.updateEndingText);
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.mediaManager.onDestroy();
        this.resumeStage.selectpicker('destroy');

        $("#add_notification").off("click", this.createNotification)
        $(".js-delete-notification").off("click", this.removeNotification);
        $(".js-update-text-count").off("keyup", this.updateTextCount);
    }

    resetEvents(){
        $(".js-delete-notification").off("click", this.removeNotification).click(this.removeNotification);
        $(".js-update-text-count").off("keyup", this.updateTextCount).keyup(this.updateTextCount);
    }

    createNotification(e){
        let $el = $(this);
        let self = application.current_handler;
        e.preventDefault();

        $.ajax({
            url: $el.data("url"),
            method: "POST",
            success: self.addNotification
        })
    }

    addNotification(html){
        let self = application.current_handler;
        $("#notifications_container").append(html);
        self.resetEvents();
    }

    removeNotification(e){
        e.preventDefault();
        let $el = $(this);
        let self = application.current_handler;

        self.confirmationModal.displayConfirm(
            I18n.t("common.title_delete"),
            I18n.t("backoffice.stages.stage_notification.destroy_confirmation_description", {title: $(this).data("title")}),
        {
                positive_btn: {
                    type: "danger",
                    label: I18n.t("common.destroy"),
                    callback: function(){
                        $("#stage_notification_"+$el.data("id")).remove();
                    }
                },
                negative_btn: { label: I18n.t("common.cancel") }
            }
        );
    }

    updateTextCount(e){
        let $el = $(this)
        let id = $el.data("id")
        let $wrap = $(`#${id}_text_count_wrap`)
        $(`#${id}_text_count`).text($el.val().length)
        if($el.val().length >= 150){
            $wrap.addClass("text-danger")
            $wrap.removeClass("text-warning")
        }else if($el.val().length >= 70){
            $wrap.removeClass("text-danger")
            $wrap.addClass("text-warning")
        }else{
            $wrap.removeClass("text-danger")
            $wrap.removeClass("text-warning")
        }
    }

    updateEndMinDate(){
        let $inputStarts = $("#stage_starts_at");
        let $inputEnds = $("#stage_ends_at");

        const startsAt = Date.parse($inputStarts.val());
        const endsAt = Date.parse($inputEnds.val());

        $inputEnds.attr("min", $inputStarts.val())
        if(startsAt > endsAt){
            $inputEnds.val($inputStarts.val())
        }
    }

    updateDateInterval(){
        if($("#stage_is_interval").val() === "true"){
            $("#openings_dates").slideUp()
            $("#openings_intervals").slideDown()
        }else{
            $("#openings_dates").slideDown()
            $("#openings_intervals").slideUp()
        }
    }

    updateEndingText(){
        if($("#stage_has_ending_text").prop("checked")){
            $("#ending_text").slideDown()
        }else{
            $("#ending_text").slideUp()
        }
    }
}