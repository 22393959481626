export default class AdminsForm {

    constructor() {
        this.dropify = null;
        this.role_select = null;
        this.department_select = null;
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.dropify = $('#profile_image').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
                'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
            }});

        this.role_select = $('#admin_role_id').selectpicker();
        //this.department_select = $('#admin_department_ids').select2();
        $("#admin_department_ids").select2({
            multiple: true,
            closeOnSelect: false
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.role_select.selectpicker('destroy');
        //this.department_select.select2("destroy");
        this.dropify.data('dropify').destroy();
        $('#admin_department_ids').select2('destroy');
    }

}