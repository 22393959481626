import Sortable from 'sortablejs';
import ConfirmationModal from "../../utils/ConfirmationModal";

export default class QuestionContent {

    constructor() {
        this.sortable = null;
        this.confirmationModal = new ConfirmationModal();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        $("#add_content").click(this.onClickAddContent);

        let $contentContainer = $("#content_container")
        this.sortable = Sortable.create($contentContainer.get(0), {
            animation: 300,
            ghostClass: "sortable_position",
            dragClass: "sortable_dragging",
            handle: '#content_container .handle',
            forceFallback: true
        });

        $contentContainer.on( "dragend", function(){
            $(document).trigger("contents:updated")
        })
        $contentContainer.keyup(this.onTyping)
        $contentContainer.on("tbwchange", this.onTyping)

        this.bindContentsEvents()
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.sortable.destroy();
        this.removeContentsEvents()
    }

    resetContentsEvents(){
        this.removeContentsEvents()
        this.bindContentsEvents()
    }

    bindContentsEvents(){
        $(".js-delete-content").click(this.removeContent);
        $("#content_container input[type=hidden]").change(this.onTyping)
    }

    removeContentsEvents(){
        $(".js-delete-content").off("click", this.removeContent);
        $("#content_container input[type=hidden]").off("change", this.onTyping)
    }

    onClickAddContent(e){
        let $el = $(this);
        let self = application.current_handler.questionContent;
        $.ajax({
            url: $el.data("url"),
            method: "post",
            data: {content_type: $("#select_content_type").val()},
            success: self.addContent
        })
    }

    addContent(html){
        let self = application.current_handler.questionContent;
        $("#content_container").append(html);
        $( document ).trigger( "question_content_updated" );
        self.resetContentsEvents();
        $(document).trigger("contents:updated")
    }

    removeContent(e){
        let $el = $(this)
        let self = application.current_handler.questionContent;
        self.confirmationModal.displayConfirm(
            I18n.t("common.title_delete"),
            I18n.t("backoffice.questions.contents.destroy_confirmation_description"),
            {
                positive_btn: {
                    type: "danger",
                    label: I18n.t("common.destroy"),
                    callback: function(){
                        $("#content_"+$el.data("id")).remove();
                        $(document).trigger("contents:updated")
                    }
                },
                negative_btn: { label: I18n.t("common.cancel") }
            }
        );
    }

    onTyping(e){
        let self = application.current_handler.questionOptions;
        clearTimeout(self.timeout);
        self.timeout = window.setTimeout(function() {
            $(document).trigger("contents:updated")
        },500);
    }
}